<template>
    <div class="info-section" :class="{ 'flip-order': flipOrder }">
      <div class="info-image">
        <img :src="imageSrc" :alt="title + ' Image'" />
      </div>
      <div class="info-text">
        <h1>{{ title }}</h1>
        <p>{{ text }}</p>
        <a v-if="showButton" href="https://www.hilton.com/en/book/reservation/rooms/?ctyhocn=SAVAPES&arrivalDate=2024-08-01&departureDate=2024-08-04&groupCode=CESSLT&room1NumAdults=1&cid=OM%2CWW%2CHILTONLINK%2CEN%2CDirectLink" target="_blank" rel="noopener noreferrer">
          <button class="book-button">Book Now</button>
        </a>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: String,
      text: String,
      imageSrc: String,
      flipOrder: Boolean,
      lastSection: Boolean,
    },
    computed: {
      showButton() {
        return this.title === "Lodging";
      },
    },
  };
  </script>
  
  <style scoped>
  .info-section {
    display: flex;
    padding: 20px;
  }
  
  .info-section.flip-order {
    flex-direction: row-reverse;
  }
  
  .info-image {
    flex: 1;
  }
  
  .info-image img {
    width: 80%;
    height: auto;
    margin-top: 7vh;
  }
  
  .info-text {
    flex: 1;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .info-text h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .info-text p {
    font-size: 1.2em;
    line-height: 1.5;
  }

  button {
    background-color: #cba751;
    border: none;
    width: 20vw;
    padding: 1vw;
    color: white;
    font-weight: bold;
    margin: 0 auto;
  }

  @media (max-width: 732px) {
    .info-section {
      flex-direction: column-reverse;
    }

    .info-section.flip-order {
      flex-direction: column-reverse;
      
    }

    button {
      width: 40vw;
      padding: 3vh;

    }


    .info-image img {
      margin-top: 5vh;
  }

  .info-text {
    padding-top: 0;
  }

 
  }
  </style>
  