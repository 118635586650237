<template>
    <div class="gallery-container">
      <h1>Family Gallery</h1>
      
      <div class="gallery">
        <img :src="currentPhoto" alt="Family Photo" class="gallery-photo">
        <button @click="prevPhoto" class="nav-arrow left">&lt;</button>
        <button @click="nextPhoto" class="nav-arrow right">&gt;</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        photos: [
          // Add more photos as needed
        ],
        currentPhotoIndex: 0,
      };
    },
    computed: {
      currentPhoto() {
        return this.photos[this.currentPhotoIndex];
      },
    },
    methods: {
      nextPhoto() {
        this.currentPhotoIndex = (this.currentPhotoIndex + 1) % this.photos.length;
      },
      prevPhoto() {
        this.currentPhotoIndex = (this.currentPhotoIndex - 1 + this.photos.length) % this.photos.length;
      },
    },
  };
  </script>
  
  <style scoped>
  .gallery-container {
    max-width: 800px;
    margin: 50px auto;
    margin-top: 0;
    min-height: 82vh;
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
  }
  
  h1 {
    color: #333;
  }
  
  .gallery {
    position: relative;
  }
  
  .gallery-photo {
    width: 30vw;
    height: auto;
    margin-left: 10vw;
    margin-right: 10vw;
  }
  
  .nav-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    background-color: #cba751;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .left {
    left: 10px;
  }
  
  .right {
    right: 10px;
  }

  @media (max-width: 768px) {
    .gallery-container {
      padding-top: 0;
      min-height: 83vh;
      
    }
  }

  @media (min-height: 1180px) {
    .gallery-container {
      min-height: 90vh;
    }
  }
  </style>
  