<template>
  <div id="app">
    <HeaderBar />
    <router-view />
    <FooterBar :isHomePage="isHomePage" />
  </div>
</template>

<script>
import HeaderBar from './components/HeaderBar.vue'
import FooterBar from './components/FooterBar.vue'

export default {
  name: 'App',
  components: {
    HeaderBar,
    FooterBar
  },
  computed: {
    isHomePage() {
      // Assuming you have a route named 'home'
      return this.$route.name === '';
    }
  }
}
</script>

<style>
body {
  margin: 0;
  display: flex;
  flex-direction: column;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.info-sections-container {
  flex: 1;
}

.last-section {
  margin-bottom: 5vh;
}

FooterBar {
  width: 100vw;

}
</style>
