<template>
    <header>
      <div class="header-container">

        <a href="/" class="logo-link">
            <div class="logo-container">
            <img src="@/assets/small-logo.png" alt="Logo" class="logo" />
            <h1>SLT Family Reunion</h1>
          </div>
        </a>
        <nav class="nav-container">
          <a href="/">Home</a>
          <!-- <a href="/gallery">Gallery</a> -->
          <a href="/faqs">FAQs</a>
          <a href="/contact">Contact Us</a>
          <a href="/rsvp" class="rsvp-link">RSVP</a>
        </nav>
        <img src="@/assets/hamburger.png" alt="hamburger-menu" class="hamburger-menu-button" @click="toggleOverlay" />
    </div>
    <nav class="mobile-nav" ref="nav" v-if="showOverlay">
        <a href="/">Home</a>
        <!-- <a href="/gallery">Gallery</a> -->
        <a href="/faqs">FAQs</a>
        <a href="/contact">Contact Us</a>
        <a href="/rsvp" class="rsvp-link">RSVP</a>
      </nav>
    </header>
</template>
  
<script>
export default {
  name: 'HeaderBar',
  data() {
    return {
      showOverlay: false,
    };
  },
  methods: {
    toggleOverlay() {
      this.showOverlay = !this.showOverlay;
    },
  },
};
</script>
  
<style scoped>

header {
  width: 100%;
  
  background-color: #000;
  color: #fff;
  padding: 10px;
  box-sizing: border-box;
  position: sticky;
  top: 0;
  z-index: 10;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  background-color: transparent;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  max-width: 80px; /* Adjust the maximum width of your logo */
  height: auto;
}

.logo-link {
  color: white;
  text-decoration: none;
}

.hamburger-menu-button {
  display: none;
}

nav {
  display: flex;
  gap: 2vw;
  align-items: center;
  font-weight: bold; 
}

nav a {
  text-decoration: none;
  color: white;
}

nav a:hover {
    text-decoration: underline;
  }

.nav-container {
  display: flex;
}

.rsvp-link {
  background-color: #cba751; /* Background color for the RSVP link */
  padding: 10px 20px; /* Adjust the padding and width based on your design */
  display: inline-block; /* Ensure inline-block display to adjust width */
}

/* Responsive Styles */
@media (max-width: 768px) {
  header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }


  .logo-container, .nav-container {
    margin-bottom: 10px; /* Add spacing between logo and navigation in mobile view */
  }

  .logo-container h1 {
    font-size: x-large;
  }

  .nav-container {
    display: none;    
  }

  .hamburger-menu-button {
    display: flex;
    width: 7vw;
    margin-right: 3vw;
  }

  .mobile-nav {
    background-color: #000;
    display: flex;
    justify-content: center;
    padding-bottom: 2vh;
    position: sticky;
    top: 15%;
    z-index: 20;
  }
}

@media (max-width: 280px) {
  nav a {
    font-size: small;
  }
}
</style>
  