<template>
    <div class="faq-container">
      <h1>Frequently Asked Questions</h1>
      <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
        <div class="question" @click="toggleAnswer(index)">{{ faq.question }}</div>
        <div :class="{ 'answer': true, 'active': faq.active }">{{ faq.answer }}</div>
      </div>
      <a href="/contact">I Have Another Question</a>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        faqs: [
          { question: 'How do I register for the family reunion?', answer: 'To register for the family reunion, please visit our registration page and follow the instructions provided.', active: false },
          { question: 'What is the schedule for the reunion events?', answer: 'The schedule for reunion events can be found on the Home page. Check the schedule for details on each day\'s activities.', active: false },
          { question: 'Is there parking available at the venue?', answer: 'Yes, there is ample parking available at the hotel and park. Attendees can use the designated parking areas.', active: false },
          { question: 'How can I purchase the family reunion t-shirt?', answer: 'The cost of the t-shirt is included in your registration fees. You\'ll get it when you check in!', active: false },
          { question: 'Are there vegetarian meal options?', answer: 'Yes, we will have vegetarian meal options available during the family reunion. Please inform us of any dietary restrictions when registering.', active: false },
          // Add more FAQs as needed
        ],
      };
    },
    methods: {
      toggleAnswer(index) {
        this.faqs[index].active = !this.faqs[index].active;
      },
    },
  };
  </script>
  
  <style scoped>
  .faq-container {
    max-width: 800px;
    min-height: 84vh;
    margin: 50px auto;
    margin-top: 0;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  h1 {
    text-align: center;
    color: #333;
    margin-bottom: 5vh;
  }
  
  .faq-item {
    margin-bottom: 20px;
  }
  
  .question {
    cursor: pointer;
    padding: 10px;
    font-weight: bold;
    border: 2px solid black;
    width: 70vw; /* Set the width to 70% */
    margin: 0 auto; /* Center the question */
  }
  
  .answer {
    display: none;
    padding: 10px;
    background-color: #f0f0f0;
    width: 70vw;
  }
  
  .answer.active {
    display: block;
  }

  a {
    background-color: #cba751;
    color: #fff;
    font-weight: bold;
    margin-top: 2vh;
    border: none;
    cursor: pointer;
    width: 20vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .faq-container {
      min-height: 83vh;
    }

    a {
      width: 40vw;
      
    }
  }

  @media (min-height: 1180px) {
    .faq-container {
      min-height: 89vh;
    }
  }

  
  </style>
  