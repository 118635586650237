<template>
    <div id="home-container">
      <HeroSection/>
      <div class="info-sections-container">
        <InfoSection
          title="Lodging"
          text="We'll be staying at Embassy Suites near Savannah Airport, just a mile from the Tanger Outlets and 20 minutes from downtown Savannah. Enjoy complimentary made-to-order breakfast, an indoor pool, a fitness center, and more!"
          :imageSrc="require('@/assets/embassy-suites.png')"
          class="first"
        />
        <InfoSection
          title="Day One: 8/1"
          text="Get checked in, then kick back and relax as everyone arrrives in Savannah! Details on activites for Thursday will be available soon."
          :imageSrc="require('@/assets/savannah-riverfront.jpg')"
          flipOrder
        />
        <InfoSection
          title="Day Two: 8/2"
          text='Join us at Villa Marie Center on Friday at 6 PM for our Sneaker Ball. Dress to impress in your finest formal attire and your best sneakers!'
          :imageSrc="require('@/assets/sneaker-ball-flyer.jpg')"
        />
        <InfoSection
          title="Day Three: 8/3"
          text="We'll head back to Villa Marie Center at 9 am for a Saturday at the Park! Bring your basketballs, footballs, fishing poles, or whatever you like. Get ready for a fun day outdoors with the whole family!"
          :imageSrc="require('@/assets/villa-marie-center-pool.jpg')"
          flipOrder
        />
        <InfoSection
          title="Day Four: 8/4"
          text="Enjoy a Sunday morning breakfast with family as we close out our reunion! Details on further activities will be available soon."
          :imageSrc="require('@/assets/breakfast.jpeg')"
          class="last-section"
        />
      </div>
    </div>
  </template>
  
  <script>
  import HeroSection from './HeroSection.vue';
  import InfoSection from './InfoSection.vue';

  
  export default {
    name: 'App',
    components: {
      HeroSection,
      InfoSection,
    },
    data() {
      return {
        showFooter: false,
      };
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    }

  };
  </script>
  
  <style scoped>
  #home-container {
    position: relative;
  }

  

  @media (min-width: 732px) {
    #home-container {
      display: flex;
      flex-direction: column;
    }

  }
  </style>
  