<template>
    <div class="rsvp-container">
        <h1>Registration Fees</h1>
        <ul>
            <li>Adults (19+): $150</li>
            <li>Teens (13-18): $90</li>
            <li>Youth (6-12): $75</li>
            <li>Children (5 and under): Free</li>
        </ul>

        <h2 id="payment-options-h2">Payment Options:</h2>

        <div id="electronic-payment">

            <div id="payment-buttons">
                <a href="https://account.venmo.com/u/sltreunion" target="_blank" rel="noopener noreferrer">Pay With Venmo</a>
                <a id="registration-button" href="/downloadable-forms/registration-form.pdf" download="SLT-Registration-2024.pdf">Mail-In Registration Form</a>
            </div>
            <h3>Or</h3>

            <p id="zelle-subheader">Direct Zelle payments to: Slt.reunion84@gmail.com</p>

        </div>

        <p id="attn">Attention: When submitting your registration fees, please include all t-shirt sizes in the memo! Shirts may fit snug, so we suggest sizing up!</p>

        <h2>Lodging:</h2>
        <p>Hotel accomodations must be booked separately through the Hilton website linked here:</p>
        <a id="lodging-button" href="https://www.hilton.com/en/book/reservation/rooms/?ctyhocn=SAVAPES&arrivalDate=2024-08-01&departureDate=2024-08-04&groupCode=CESSLT&room1NumAdults=1&cid=OM%2CWW%2CHILTONLINK%2CEN%2CDirectLink" target="_blank" rel="noopener noreferrer">Book Lodging</a>
    </div>
</template>

<script>
    
</script>

<style scoped>

    .rsvp-container {
        min-height: 90vh;
    }

    ul {
        padding-left: 0;
    }

    li {
        list-style: none;
        margin-bottom: 1.5vh;
    }

    a {
        text-decoration: none;
        font-weight: bold;
        background-color: #cba751;
        color: white;
        padding: 2vh;
        display: block;
        width: 20vw;
        margin: auto;
        margin-top: 1vh;
    }

    #electronic-payment, #mail-payment {
        min-width: min-content;
        width: 80%;
        margin: auto;
        border: 2px solid black;
    }

    #payment-buttons {
        display: flex;
        flex-direction: row;
        width: fit-content;
        margin: auto;
        margin-top: 3vh;
        gap: 6vw;
    }

    #zelle-subheader {
        font-weight: bold;
    }

    #payment-options-h2 {
        margin-bottom: 3vh;
    }

    #attn {
        width: 90vw !important;
        margin: auto;
        margin-top: 5vh;
        margin-bottom: 3vh;
        font-weight: bold;
    }

    #lodging-button {
        margin-bottom: 5vh;
    }

    @media (max-width: 768px) {
        .rsvp-container{
            min-height: 89vh;
        }

        a {
            display: block;
            width: 40vw;
            margin: auto;
            margin-top: 1vh;
        }

    }

    @media screen and (max-width: 992px) {
        #payment-buttons {
            flex-direction: column;
        }
    }

    @media (min-height: 1180px) {
        .rsvp-container {
        min-height: 93vh;
        }

        p, li, a {
            font-size: x-large;
        }

        a {
            width: 30vw;
        }

        h2 {
            font-size: xx-large;
        }

        #zelle-subheader {
            font-size: x-large;
        }


  }



</style>