<template>
    <footer>
      <div class="footer-container">
        <div class="nav-container">
          <nav>
            <a href="/">Home</a>
            <!-- <a to="/gallery">Gallery</a> -->
            <a href="/faqs">FAQs</a>
            <a href="/contact">Contact Us</a>
          </nav>
        </div>
        <div class="logo-container">
          <img src="@/assets/small-logo.png" alt="Logo" class="logo" id="footer-logo"/>
        </div>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    
  };
  </script>
  
  <style scoped>
  footer {
    width: 100vw;
    background-color: #000;
    color: #fff;
  }

  
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .nav-container {
    display: flex;
    align-items: center;
  }
  
  nav {
    display: flex;
    gap: 2vw;
    margin-left: 2vw;
    font-weight: bold;
  }
  
  nav a {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
  }

  nav a:hover {
    text-decoration: underline;
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    margin-right: 1vw;
  }
  
  .logo {
    max-width: 80px;
    height: auto;
    margin: 0; /* Remove any margin */
  }

  @media (max-width: 732px) {
  .logo-container {
    display: none; /* Hide the logo on small screens */
  }

  .footer-container {
    padding-top: 2vh;
    padding-bottom: 2vh;
    justify-content: center;
  }

  nav {
    display: flex;
    gap: 3vh;
  }

}

@media (max-width: 280px) {
  nav {
    gap: 2vh
  }
}
  </style>
  