import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router'; // Import the necessary router functions
import HomePage from './components/HomePage.vue'; // Assuming you store your Home component in a 'views' folder
import GalleryPage from './components/GalleryPage.vue';
import FrequentlyAskedQuestions from './components/FrequentlyAskedQuestions.vue';
import ContactUs from './components/ContactUs.vue';
import RsvpPage from './components/RsvpPage.vue';

// Create a router instance with your routes
const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: HomePage },
    { path: '/faqs', component: FrequentlyAskedQuestions},
    { path: '/contact', component: ContactUs},
    { path: '/gallery', component: GalleryPage},
    { path: '/rsvp', component: RsvpPage}
    // Add more routes if needed
  ],
});

// Create the Vue app with the router
const app = createApp(App);
app.use(router);

// Mount the app and router
app.mount('#app');
