<template>
  <div class="contact-container">
    <h1>Contact Us</h1>
    <p>If you have any questions or inquiries about the family reunion, feel free to reach out to us. We're here to help!</p>

    <form ref="contactForm" @submit.prevent="submitForm" class="contact-form">
      <label for="name">Name:</label>
      <input type="text" id="name" v-model="formData.name" required>

      <label for="email">Email:</label>
      <input type="email" id="email" v-model="formData.email" required>

      <label for="message">Message:</label>
      <textarea id="message" v-model="formData.message" required></textarea>

      <button type="submit">Submit</button>
    </form>

    <p v-if="formStatus === 'success'">Your inquiry has been submitted, we'll get back with you shortly!</p>
  </div>
</template>

<script>
import emailjs from 'emailjs-com';

export default {
  data() {
    return {
      formData: {
        name: '',
        email: '',
        message: '',
      },
      formStatus: null, // To track the form submission status
      serviceId: 'service_u7x65gr', // Replace with your EmailJS service ID
      templateId: 'template_afbdxh5', // Replace with your EmailJS template ID
      userId: 'FAjawMz-U3os-0pLZ', // Replace with your EmailJS public key
    };
  },
  methods: {
    async submitForm() {
      // Check if all inputs are filled
      if (!this.formData.name || !this.formData.email || !this.formData.message) {
        this.formStatus = 'required';
        return;
      }

      try {
        // Manually construct template parameters
        const templateParams = {
          'name': this.formData.name,
          'email': this.formData.email,
          'message': this.formData.message,
        };

        // Send data using emailjs
        const emailjsResponse = await emailjs.send(
          this.serviceId,
          this.templateId,
          templateParams,
          this.userId
        );

        // Log the emailjs response (optional)
        console.log('EmailJS Response:', emailjsResponse);

        // Set form status to success
        this.formStatus = 'success';

        // Optionally, you can add logic to handle the response or update the UI accordingly
        console.log('Your inquiry has been submitted, we\'ll get back with you shortly!');

      } catch (error) {
        // Handle errors, log them, or update the UI accordingly
        console.error('Error submitting form:', error);
      }

      // Optionally, you can reset the form data
      this.formData = {
        name: '',
        email: '',
        message: '',
      };
    },
  },
};
</script>
  
  <style scoped>

  .contact-container {
    max-width: 800px;
    min-height: 89vh;
    margin: 50px auto;
    margin-top: 0;
    margin-bottom: 0;
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
  }
  
  h1 {
    color: #333;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  label {
    margin-top: 10px;
    font-weight: bold;
  }
  
  input,
  textarea {
    width: 70vw;
    padding: 8px;
    margin-top: 5px;
    border: 2px solid black;
    box-sizing: border-box;
  }

  textarea {
    height: 15vh;
  }
  
  button {
    background-color: #cba751;
    color: #fff;
    font-weight: bold;
    font-size: medium;
    padding: 2vh;
    margin-top: 4vh;
    border: none;
    cursor: pointer;
    width: 20vw;
  }

  @media (max-width: 768px) {
    .contact-container {
      margin: 0;
      min-height: 90vh;
    }

    button {
      width: 40vw;
    }
  }

  @media (min-height: 1180px) {
    .contact-container {
      min-height: 93vh;
      font-size: x-large;
    }
  }
  </style>
  