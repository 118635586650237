<template>
  <div class="hero-container">
    <div class="hero-text">
      <h1>SLT Reunion 2024</h1>
      <h1>August 1st-4th</h1>
      <h1>Join us in Savannah!</h1>
      <a href="/rsvp" class="rsvp-button">RSVP</a>
    </div>
    <div class="hero-image">
      <img src="@/assets/reunited-image.png" alt="Hero Image" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HeroSection',
    props: {
      msg: String
    }
  }
</script>

<style scoped>
.hero-container {
  display: flex;
  min-height: 90vh;
}

.hero-text {
  flex: 1;
  padding: 20px;
  padding-top: 0;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  gap:2vh;
}

.hero-text h1 {
  margin: 0;
}

.rsvp-button {
  background-color: #cba751;
  color: #fff;
  padding: 10px 20px;
  width: 15vw;
  border: none;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.hero-image {
  flex: 1;
  display: block;
  width: 40vw;
  margin: auto;
}

.hero-image img {

  display: block;
  width: 80%;
  margin: auto;
  /* width: 80%;
  height: auto;
  margin-top: 7vh; */
}

@media (max-width: 768px) {
  .hero-container {
    display: flex;
    flex-direction: column;
    min-height: 80vh;
  }

  .hero-text {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center horizontally */
    justify-content: center; /* Center vertically */
    padding: 20px;
    box-sizing: border-box; /* Ensure padding is included in the width */
  }

  .hero-image {
    width: 100%; /* Use full width */
    text-align: center; /* Center the image within the container */
  }

  .hero-image img {
    width: 80%;
    height: auto;
    margin-top: -3vh;
    display: block;
    margin: auto; /* Center the image horizontally */
  }

  .rsvp-button {
    width: 80%; /* Adjust width based on your design */
    max-width: 200px; /* Set a maximum width if needed */
    margin-top: 2vh;
    padding: 2vh;
    box-sizing: border-box; /* Ensure padding is included in the width */
  }

  
}

@media (min-height: 1366px) {
  .hero-container {
    display: flex;
    flex-direction: column;
    min-height: 90vh;
  }

  .hero-image {
    width: 100%; /* Use full width */
    text-align: center; /* Center the image within the container */
  }

  .hero-image img {
    width: 80%;
    height: auto;
    margin-top: -3vh;
    display: block;
    margin: auto; /* Center the image horizontally */
  }
}

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
